require('./cookie_bar.css')
const setCookie = function () {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = "cookies-accepted=true; expires=" + d.toUTCString() + "; sameSite=Strict;";
};

const getCookie = function (cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
};

document.addEventListener("DOMContentLoaded", function () {
    if (getCookie('cookies-accepted') !== 'true') {
        showCookieBar();
    }
    document.getElementById('accept-cookies').addEventListener('click', function (event) {
        event.preventDefault();
        removeCookieBar();
        setCookie();
    });
});

function removeCookieBar() {
    let cookiesBar = document.getElementById('cookies-info');
    cookiesBar.parentNode.removeChild(cookiesBar);
}

function showCookieBar() {
    document.getElementById('cookies-info').style.display = 'block';
}
